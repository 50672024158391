import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import spinner from "./spinner.svg";

const apiKey = "07ed5f76e4705d39b91f702f80b93007";
const weatherUrl = `http://api.openweathermap.org/data/2.5/weather?q=stockholm&appid=${apiKey}&units=metric`;

interface Temp {
  temperature?: number;
}

const theme = {
  color: "#2B3E42",
  background: "#d5e1dd",
};

const Wrapper = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    flex: 0 1 auto;
  }
`;

const Background = styled.div`
  background-color: #d5e1dd;
  min-height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading1 = styled.h1`
  color: ${theme.color};
  font-size: 3rem;
`;

const Heading3 = styled.h3`
  color: ${theme.color};
`;

const Paragraph = styled.p`
  color: ${theme.color};
  width: 100%;
`;

const App: React.FunctionComponent = () => {
  const [temp, setTemp] = useState<Temp>();
  const [error, setError] = useState(false);
  useEffect(() => {
    const weather = fetch(weatherUrl)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const temperature = data.main.feels_like;
        setTemp({ temperature: ~~(temperature * 100) });
      })
      .catch((err) => {
        setError(true);
      });
  }, []);

  return (
    <Background>
      <Wrapper>
        <Heading1>Wandarskalan</Heading1>
        <Heading3>Hur varmt är det ute?</Heading3>
        {!error && (
          <Paragraph style={{ width: "auto" }}>
            Det är{" "}
            <strong>
              {!temp && <img src={spinner} />}
              {temp?.temperature} wandar
            </strong>{" "}
            i Stockholm.
          </Paragraph>
        )}
        {error && (
          <Paragraph style={{ width: "auto" }}>
            <strong>
              <em>Det gick tydligen inte att hämta temperaturen just nu. </em>
            </strong>
            🤷
          </Paragraph>
        )}
        <br />
        <Heading3 style={{ marginBottom: 0 }}>Vad är detta?</Heading3>
        <Paragraph>
          Wandarskalan är en temperaturskala som tar fasta på hur varmt det{" "}
          <em>känns</em> ute. Till skillnad från andra tjänster levererar inte
          wandarskalan värdet i samma enhet som den "riktiga" temperaturen och
          därmed urvattnas inte innebörden av värdet.
        </Paragraph>
        <Paragraph>
          Värdet levereras som 100 x temperaturen i celcius. Detta för tydlig
          särskiljning och inte minst för dramatisk effekt.
        </Paragraph>
      </Wrapper>
    </Background>
  );
};

export default App;
